import {advancedSettingsKeys, EDITOR_CONTENT_LITERAL, templateRelatedKeys} from "./template_related_keys";
import Utils from "../utils";
import {type AxiosResponse} from "axios";
import AdvancedSettingsData from "./advanced_settings_data";
import {toHtmlEntities, fromHtmlEntities} from "@avvoka/shared"
import {Delta, isInsert} from "@avvoka/editor"
import {Optional} from "@avvoka/shared"

function decodeEditorDelta(delta: Delta) {
  for (const op of delta.ops) {
    if (isInsert(op) && typeof op.insert === 'string') op.insert = fromHtmlEntities(op.insert)
  }

  return delta
}

function encodeEditorDelta(delta: Delta) {
  const docxHtmlEntities = {
    60: '&lt;',
    62: '&gt;',
    34: '&quot;',
    38: '&amp;'
  }

  for (const op of delta.ops) {
    if (isInsert(op) && typeof op.insert === 'string') op.insert = toHtmlEntities(op.insert, docxHtmlEntities, false)
  }

  return delta
}

export default class AutosaveUtils {
  static loadErrorMessage = localizeText('autosave.errors.load')
  static saveErrorMessage = localizeText('autosave.errors.load')
  static destroyErrorMessage = localizeText('autosave.errors.destroy')
  static initialState = ''

  public static shouldDoRequest() {
    const changeWasMade = AutosaveUtils.initialState !== AutosaveUtils.data()
    return changeWasMade && !window.avvLoading
  }

  public static initializeState(){
    AutosaveUtils.initialState = AutosaveUtils.data()
  }

  public static async load(){
    const response = await Utils.axios.get(AutosaveUtils.URL)
    AutosaveUtils.handleResponse(response, AutosaveUtils.loadErrorMessage, AutosaveUtils.applyData)
  }

  public static async save(): Promise<void> | never {
    if(!AutosaveUtils.shouldDoRequest()) return
    const response = await Utils.axios.post(AutosaveUtils.URL, {template_autosave: AutosaveUtils.data()})
    AutosaveUtils.handleResponse(response, AutosaveUtils.saveErrorMessage, AutosaveUtils.afterSave)
  }

  public static async beaconSave(){
    if(!AutosaveUtils.shouldDoRequest()) return
    const data = new FormData()
    data.append('authenticity_token', Utils.CSRFToken)
    data.append('template_autosave', AutosaveUtils.data())
    window.navigator.sendBeacon(AutosaveUtils.URL, data)
  }

  public static async destroy(){
    const response = await Utils.axios.delete(AutosaveUtils.URL)
    AutosaveUtils.handleResponse(response, AutosaveUtils.destroyErrorMessage)
  }

  public static saveTemplate(){
    document.getElementById('save-template')!.click()
  }

  public static afterSave(){
    AutosaveUtils.initializeState()
    AutosaveUtils.showAutosaveUI()
  }

  private static handleResponse(response: AxiosResponse, errorMessage: string, callback?: (response: AxiosResponse) => void){
    const validStatuses = [200, 204]
    if(!validStatuses.includes(response.status)) avv_dialog({snackMessage: errorMessage, snackStyle: 'error'})
    else if(callback) {
      callback(response)
    }
  }

  private static applyData(response: AxiosResponse){
    const { data } = response
    if(!data) return
    Object.keys(data).forEach(key => {
      if(key === EDITOR_CONTENT_LITERAL) EditorFactory.main.load(decodeEditorDelta(data[key]))
      if(advancedSettingsKeys.includes(key)) AdvancedSettingsData.write(key, data[key])
      AvvStore.state[key] = data[key]
    })
  }

  public static data(){
    const slicedData = templateRelatedKeys.reduce((acc, key) => {
        acc[key] = AutosaveUtils.getData(key)
        return acc;
      }, {})

    return JSON.stringify(slicedData)
  }

  private static getData(key: string){
    if(key === EDITOR_CONTENT_LITERAL) return EditorFactory.mainOptional.andThen(editor => Optional.of(encodeEditorDelta(editor.getDelta()))).getOr(new Delta())
    if(advancedSettingsKeys.includes(key)) return AdvancedSettingsData.read(key)
    return AvvStore.state[key]
  }

  private static showAutosaveUI(){
    const elementsToShow = Array.from(document.querySelectorAll<HTMLElement>('.autosave-show-ui'))
    const showElement = (element: HTMLElement) => element.classList.remove('hidden')
    elementsToShow.forEach(showElement)
  }
  
  private static get URL () {
    return `/template_versions/${AvvStore.state.template_version_id}/template_autosave`
  }
}
