export const templateDetailsKeys = ['description', 'folder_ids', 'private']

export const advancedSettingsKeys = [
  'legacy_folder_id',
  'legacy_signed_folder_id',
  'email_template_id',
  'secondary_email_template_id',
  'allow_create_from_archived',
  'disable_publish_email_message',
  'override_mass_email_template',
  'pdf_template_id',
  'show_tracking_changes_on_first_draft',
  'docx_no_unanswered_placeholders',
  'rights_locked',
  'add_to_related_docs',
  'guest_access',
  'guest_access_only',
  'preferred_intro_page',
  'disallow_download_pdf',
  'disallow_download_docx',
  'disallow_download_for_cp',
  'sign_method',
  'signature_resolving',
  'integration_docu_sign_split_attachments',
  'sign_with_signatory_title',
  'tick_to_complete',
  'skip_to_signature',
  'allow_create_datasheet_row_in_questionnaire',
  'allow_edit_datasheet_row_in_questionnaire',
  'rtl',
  ...templateDetailsKeys
]

export const EDITOR_CONTENT_LITERAL = 'editor_content_autosave'

export const templateRelatedKeys = [
  'disabledSystemNotifications',
  'operations',
  'parties',
  'question_template',
  'questionnaires',
  'questions',
  'rules',
  'start_document_party',
  'template_auto_name',
  'template_name',
  'docxOptions',
  'allowed_webhook_triggers',
  EDITOR_CONTENT_LITERAL,
  ...advancedSettingsKeys
]
